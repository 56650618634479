<template>
    <base-index
        title="Customer List"
        :columns="columns"
        @onPageChanged="onPageChanged"
        :entities="entities"
        :config="config">

        <template #cell_telegram_group_chat_id="{entity}">
            <span class="d-flex justify-content-center display-4 ">{{ entity.telegram_group_chat_id ? '✅' : '-' }}</span>
        </template>

        <template #filters>
            <b-col sm="3">
                <fieldset class="form-group">
                    <div>
                        <label class="form-control-label"> Search </label>
                        <div class="has-label">
                            <el-input
                                filterable
                                remote
                                clearable
                                @input.native="getEntities"
                                @change="getEntities"
                                :remote-method="getEntities"
                                placeholder="Search By Name Or Code"
                                v-model="filter.search"
                            >
                            </el-input>
                        </div>
                    </div>
                </fieldset>
            </b-col>
        </template>

    </base-index>
</template>
<script>
import BaseIndex from '@/components/Custom/BaseIndex'
import store from './store';
export default {
    methods: {
        getEntities() {
            store.dispatch('getEntities', this.filter);
        },
        onDeleteEntity(entity) {
            store.dispatch('destroy', entity).then(() => {
                this.toastSuccess('Customer Deleted');
                this.getEntities();
            });
        },
        onPageChanged(page) {
            this.filter.page = page;
            this.getEntities();
        }
    },
    computed: {
        entities() {
            return store.getters.entities;
        }
    },
    data() {
        return {
            filter: {
                page: 1,
                search: ''
            },
            columns: [
                {
                    key: 'name',
                    label: 'name',
                },
                {
                    key: 'username',
                    label: 'username',
                },
                {
                    key: 'code',
                    label: 'Code',
                },
                {
                    key: 'phone',
                    label: 'phone',
                },
                {
                    key: 'telegram_group_chat_id',
                    label: 'Telegram Group Chat',
                }
            ],
            config: {
                getEntities: this.getEntities,
                onDeleteEntity: this.onDeleteEntity,
                showAddNew: true,
                onClickAddNew: () => this.$router.push({ name: 'customers.create' }),
                onClickEdit: (entity) => this.$router.push({ name: 'customers.edit', params: { id: entity.id }})
            }
        }
    },
    components: {
        BaseIndex
    }
}
</script>
